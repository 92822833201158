import React, { useContext, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"
import styles from "./utils/summary.module.scss"

import Layout from "layout"
import Container from "layout/Container"
import Section from "elements/Section"
import Message from "elements/Message"
import EditDetailsButton from "elements/EditDetailsButton"
import { isBrowser } from "services/general"

import EnrollmentConsent from "./EnrollmentConsent"

import { sendEnrollmentForm } from "../services/enrollment"
import { AppContext } from "../../../context/AppContext"

import patientEnrollmentTemplate from "../utils/templates/patientEnrollmentEmailTemplate"
import { patientEnrollmentTicketBody } from "../utils/templates/patientEnrollmentZendeskTemplate"
import { patientTextMessageTemplate } from "../utils/templates/enrollmentTextMessageTemplate"
import { useMentalHealthFormFields } from "../hooks/useAirtableMHFormFields"
import { get } from "lodash"
import { hasIncompleteValues } from "../services/form"

let { parseFormField } = require("services/airtable")

const EnrollmentSummary = (props) => {
  const [loading, setLoading] = useState(false)
  const { state, dispatch } = useContext(AppContext)

  let { pageContext } = props
  let { module, nextPath } = pageContext

  const { enrollment } = state

  let { sectionFormFields, validationSchema } = useMentalHealthFormFields({
    formFields: pageContext.formFields,
  })
  let sectionSummaryFields = parseFormField(
    pageContext.summaryFields.map((formField) => ({
      ...formField,
      section: formField.summarySection,
    }))
  )
  sectionSummaryFields = sectionSummaryFields.sort(
    (firstSection, secondSection) => firstSection.order - secondSection.order
  )

  const handleSuccessSubmit = async (userData, userAddresses) => {
    setLoading(false)
    await dispatch({ type: "RESET_DETAILS" })
    await dispatch({ type: "REMOVE_CONTEXT_FROM_SESSION" })

    if (isBrowser()) {
      sessionStorage.setItem("userData", JSON.stringify({ ...userData }))
      sessionStorage.setItem("addresses", JSON.stringify(userAddresses))
    }
    navigate(nextPath)
  }

  const handleErrorSubmit = () => {
    setLoading(false)
  }

  const handleSubmit = async (values) => {
    setLoading(true)

    await sendEnrollmentForm({
      enrollmentData: enrollment,
      emailTemplate: patientEnrollmentTemplate,
      ticketTemplate: patientEnrollmentTicketBody,
      type: "patient",
      callback: handleSuccessSubmit,
      errorCallback: handleErrorSubmit,
      textMessageTemplate: patientTextMessageTemplate,
      module: module.name,
    })
  }

  return (
    <Layout
      title={pageContext?.module?.title}
      subtitle={pageContext?.module?.subtitle}
      seoTitle={pageContext?.module?.title}
      pageContext={pageContext}
      verifyPage
      display={{ footer: false, helpCenterBanner: false }}
    >
      <Container isCentered>
        {sectionSummaryFields.map((section) => {
          const route = section.link
          // const route = `${section.link}${
          //   section.sectionId ? `#${section.sectionId}` : ""
          // }`
          return (
            <Section
              title={section?.section}
              addOns={{
                right: <EditDetailsButton route={route} />,
              }}
            >
              {!hasIncompleteValues({
                data: enrollment,
                fields: section.fields,
              }) ? (
                <div className="table-container">
                  <table class="table is-fullwidth is-size-5">
                    <tbody>
                      {section.fields
                        .sort((firstField, secondField) => {
                          return firstField.order - secondField.order
                        })
                        .map((field) => {
                          let finalValue = null
                          switch (field.type) {
                            case "date":
                              finalValue = get(enrollment, field.name)
                                ? `${
                                    get(enrollment, field.name)?.month?.label
                                  } ${
                                    get(enrollment, field.name)?.date?.label
                                  }, ${get(enrollment, field.name)?.year}`
                                : ""
                              break
                            case "address":
                              finalValue = get(enrollment, field.name)
                                ? `${
                                    get(enrollment, field.name)?.streetAddress
                                  }, ${
                                    get(enrollment, field.name)?.city?.label
                                  }, ${
                                    get(enrollment, field.name)?.province?.label
                                  }`
                                : ""
                              break
                            case "select":
                              finalValue = get(enrollment, field.name)
                                ? get(enrollment, field.name)?.label
                                : ""
                              break
                            default:
                              finalValue = get(enrollment, field.name)
                              break
                          }

                          if (!!finalValue)
                            return (
                              <tr>
                                <td>{field.summaryLabel}</td>
                                <td
                                  className={classNames(
                                    "has-text-weight-bold",
                                    styles["summary__tableData"]
                                  )}
                                >
                                  {finalValue}
                                </td>
                              </tr>
                            )
                          return null
                        })}
                    </tbody>
                  </table>
                </div>
              ) : (
                <Message color="warning">
                  Some required information is missing or incomplete. Please
                  double check if you have filled out the required fields.
                </Message>
              )}
            </Section>
          )
        })}
        <EnrollmentConsent
          sectionFormFields={sectionFormFields}
          validationSchema={validationSchema}
          handleSubmit={handleSubmit}
          pageContext={pageContext}
          loading={loading}
        />
      </Container>
    </Layout>
  )
}

export default EnrollmentSummary
