const printDateValue = (date) => {
  if (!date.month.value || !date.date.value || !date.year) return "N/A"
  return `${date.month.value} ${date.date.value} ${date.year}`
}

export const patientEnrollmentTicketBody = (request) => {
  let {
    firstName,
    lastName,
    birthday,
    mobileNumber,
    gender,
    address,
    email,
    employeeNumber,
    site,
    department,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
  } = request

  return `-Personal Details-
  First Name: ${firstName}
  Last Name: ${lastName}
  Birthdate: ${printDateValue(birthday)}
  Mobile Number: ${mobileNumber}
  Gender: ${gender}
  
  -${address?.addressType} Address-
  Street Address: ${address?.streetAddress}
  City: ${address?.city?.value}
  Province: ${address?.province?.value}

  -Employee Information-
  Work Email: ${email}
  Employee Number: ${employeeNumber}
  Site: ${site?.label || "N/A"}
  Department: ${department}

  -Emergency Contact-
  Name: ${emergencyContactName || "N/A"}
  Mobile Number: ${emergencyContactNumber || "N/A"}
  Relationship to Emergency Contact: ${emergencyContactRelationship || "N/A"}
  `
}
