// import { isBrowser } from "services/general"
// import { sendMessage } from "services/telerivet"
// import { sendToZendesk } from "services/zendeskService"
import { zendesk } from "services/zendesk"
import { sendEmail } from "services/sendEmail"
import { checkIfAddressIsUnique } from "../utils/checkIfAddressIsUnique"

import { getSignedInUser } from "../../Auth/services/user"
import { checkIfUserAlreadyExists } from "../../Auth/services/signup"
import { getUserAddresses, getUserData } from "../../Auth/services/signin"

import {
  GATSBY_WEBSITE_URL,
  GATSBY_ZENDESK_ENROLLMENT_CUSTOM_FIELD_ID,
  GATSBY_FIREBASE_PROJECT_ID,
  GATSBY_FIREBASE_USER_ROLE_ID,
  GATSBY_AWS_SES_EMAIL_TO,
  GATSBY_AWS_SES_EMAIL_FROM,
  GATSBY_ENV,
} from "gatsby-env-variables"

import firebase from "firebase"

const formatName = (firstName, lastName, middleInitial) => {
  const MI = middleInitial ? ` ${middleInitial} ` : " "
  return `${firstName}${MI}${lastName}`
}

const capitalize = (string) => {
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`
}

export const sendEnrollmentForm = async (config) => {
  let { errorCallback, callback, enrollmentData } = config
  let {
    firstName,
    lastName,
    gender,
    birthday,
    mobileNumber,
    department,
    employeeNumber,
    site,
    emergencyContactName,
    emergencyContactNumber,
    emergencyContactRelationship,
    address,
  } = enrollmentData

  let emergencyContact = {
    name: emergencyContactName,
    mobileNumber: emergencyContactNumber,
    relationship: emergencyContactRelationship,
  }

  try {
    let { authUser, addresses, userData } = getSignedInUser()

    let email = authUser?.email
    let authUid = authUser?.uid
    let addressesId = addresses?.id || ""
    let addressesList = addresses?.addresses || []
    let userRoles =
      userData?.roles?.filter(
        (role) => role.subdomain !== GATSBY_WEBSITE_URL
      ) || []
    let userAllowedSubdomains =
      userData?.allowedSubdomains?.filter(
        (subdomain) => subdomain !== GATSBY_WEBSITE_URL
      ) || []

    if (!authUid) {
      userData = await checkIfUserAlreadyExists(authUser.email)
      authUid = userData?.authUid
    }

    // Add  address details
    let addressInformation = {
      primary: true,
      type: address?.addressType?.toLowerCase(),
      streetAddress: address?.streetAddress,
      city: address?.city?.value,
      province: address?.province?.value,
    }

    // Check if address exists
    let isAddressUnique = checkIfAddressIsUnique(
      addressInformation,
      addressesList
    )
    let finalAddressList = []
    if (isAddressUnique) {
      // Or remove primary from others, use new address as primary (since most recent enrollment --> most recent address)
      if (addressesList?.length > 0) {
        addressesList.forEach((address) => {
          address.primary = false
        })
      }
      finalAddressList = [...addressesList, { ...addressInformation }]
    } else {
      finalAddressList = [...addressesList]
    }

    let newAddress = await firebase
      .firestore()
      .collection("addresses")
      .add({ addresses: [...finalAddressList] })
    addressesId = newAddress.id

    let { month, date, year } = birthday

    const USER_DOCUMENT = {
      firstName: firstName || "",
      lastName: lastName || "",
      mobileNumber: mobileNumber || "",
      email: email || "",
      addresses: addressesId,
      birthday: {
        month: month,
        date: date,
        year: year,
      },
      emergencyContact: { ...emergencyContact },
      employeeNumber: employeeNumber,
      department: department,
      site: site,
      gender: gender,
      roles: [
        ...userRoles,
        {
          status: "active",
          subdomain: GATSBY_WEBSITE_URL,
          role: GATSBY_FIREBASE_USER_ROLE_ID,
          projectId: GATSBY_FIREBASE_PROJECT_ID,
        },
      ],
      allowedSubdomains: [...userAllowedSubdomains, GATSBY_WEBSITE_URL],
    }

    // User data will always be null
    if (userData?.id) {
      await firebase
        .firestore()
        .collection("users")
        .doc(userData?.id)
        .update({ ...USER_DOCUMENT, authUid })
    } else {
      await firebase
        .firestore()
        .collection("users")
        .add({ ...USER_DOCUMENT, authUid })
    }

    // Add email to employee object
    enrollmentData.email = email

    await sendEnrollmentFormToZendesk({
      config,
      document: { ...USER_DOCUMENT, authUid },
    })
    await sendEnrollmentFormToEmail(config)
    // await sendMessage({
    //   recipient: employee?.mobileNumber,
    //   message: textMessageTemplate(GATSBY_ENV),
    // })

    let newUserData = await getUserData({ authUid })
    let userAddresses = await getUserAddresses({ addressesId })

    if (callback) callback(newUserData, userAddresses)
  } catch (error) {
    console.log(error)
    if (errorCallback) errorCallback()
  }
}

export const sendEnrollmentFormToEmail = (config) => {
  let { enrollmentData, emailTemplate, type } = config
  const { firstName, lastName, middleInitial, email } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  const subject = `Concentrix Mental Health Program: ${capitalize(
    type
  )} Enrollment for ${fullname} has been ${
    type === "doctor" ? "Approved" : "Received"
  }`
  return sendEmail({
    to: GATSBY_AWS_SES_EMAIL_TO,
    cc: email,
    from: GATSBY_AWS_SES_EMAIL_FROM,
    subject,
    html: emailTemplate(enrollmentData),
  })
}

export const sendEnrollmentFormToZendesk = async ({ config, document }) => {
  try {
    let enrollmentRequestTemplate = generateZendeskRequestTemplate({
      config,
      document,
    })

    await zendesk({ module: config.module }).post(
      "/requests",
      enrollmentRequestTemplate
    )
  } catch (error) {
    console.log(error)
  }
}

export const generateZendeskRequestTemplate = ({ config, document }) => {
  let { enrollmentData, ticketTemplate, type } = config
  const { firstName, lastName, middleInitial, email } = enrollmentData
  const fullname = formatName(firstName, lastName, middleInitial)

  let isTest = GATSBY_ENV !== "production"
  let tags = [`${type}_enrollment`, `concentrix`, `concentrix_enrollment`]
  if (isTest) tags.push("test")
  const subject = `${isTest && "[TEST] "}[Concentrix] Enrollment`

  let customFields = []
  customFields.push({
    id: GATSBY_ZENDESK_ENROLLMENT_CUSTOM_FIELD_ID,
    value: JSON.stringify(document),
  })

  return {
    request: {
      type: "request",
      tags,
      subject,
      requester: {
        name: fullname || "",
        email: email || "it@medgrocer.com",
      },
      comment: { body: ticketTemplate(enrollmentData) },
      custom_fields: customFields,
    },
  }
}
